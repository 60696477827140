import Auth from "./Auth";
import BuyProductFromSupplier from "./BuyProductFromSupplier";
import Campaigns from "./Campaigns";
import Categories from "./Categories";
import Couriers from "./Couriers";
import Home from "./Home";
import News from "./News";
import Orders from "./Orders";
import Payments from "./Payments";
import Products from "./Products";
import Settings from "./Settings";
import Users from "./Users";
import Warehouses from "./Warehouses";
import Tickets from "./Tickets";

export default [
    {
        name: 'home',
        path: '/home',
        component: Home
    },
    {
        name: 'orders',
        path: '/orders',
        component: Orders
    },
    {
        name: 'couriers',
        path: '/couriers',
        component: Couriers
    },
    {
        name: 'warehouses',
        path: '/warehouses',
        component: Warehouses
    },
    {
        name: 'payments',
        path: '/payments',
        component: Payments
    },
    {
        name: 'buy-product-from-supplier',
        path: '/buy-product-from-supplier',
        component: BuyProductFromSupplier
    },
    {
        name: 'auth',
        path: '/auth',
        component: Auth
    },
    {
        name: 'tickets',
        path: '/tickets',
        component: Tickets
    },
    {
        name: 'users',
        path: '/users',
        component: Users
    },
]
