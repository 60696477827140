import { IonButton, IonCheckbox, IonCol, IonRow, IonInput, IonIcon, IonSearchbar, IonCard, IonCardContent, IonFab, IonFabButton, useIonViewDidLeave, useIonViewDidEnter, useIonViewWillEnter, IonPopover, IonContent, useIonPopover, IonListHeader, IonItem, IonList, IonSelectOption } from "@ionic/react";
import { add, chevronBack, chevronDown, chevronForward, chevronUp, document, documentSharp, menuSharp, refresh, searchCircleOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import { useInterval } from "../../hooks/useInterval";
import { searchEntity } from "../../services";
import './styles.css';
import { get, uniq } from 'lodash'
import Select from "../Select";
import xlsx from 'json-as-xlsx';
import FilterObjectIdField from "../FilterObjectIdField";
import FilterDateField from "../FilterDateField";


const intervalOptions = [
    {
        label: '10 sn',
        value: 10000
    },
    {
        label: '30 sn',
        value: 30000
    },
    {
        label: '1 dəq',
        value: 60000
    },
    {
        label: '5 dəq',
        value: 300000
    },
]

const initialData = {
    data: [],
    limit: 10,
    skip: 0
}





const PopoverList = (prop) => (
    <IonList>
        <IonListHeader>Aksiyalar</IonListHeader>

        {prop.buttons.map((b, i) => <IonItem {...b} key={i}
            disabled={b.disableCondition} button>
            {b.label}
        </IonItem>)}
        <IonItem lines="none" detail={false} button onClick={prop.onHide}>
            Bağla
        </IonItem>
    </IonList>
);


const DataTable = (prop) => {
    const [present, dismiss] = useIonPopover(PopoverList, { onHide: () => dismiss(), ...prop });

    const [data, setData] = useState(initialData);
    const [searchText, setSearchText] = useState('');
    const [intervalTime, setIntervalTime] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);


    const initialObj = {
        limit: 10,
        skip: 0,
        sort: { createdAt: 'desc' },
        filter: {},
        populate: prop.populate
    }

    const [obj, setObj] = useState(initialObj)

    const [api] = useApi();


    const fetchData = async () => {
        try {
            const res = await api(searchEntity, { ...obj, entity: prop.entity });
            if (res) setData(res)
        } catch (error) {
            setData(initialData)
        }
    }

    const onSearch = async e => {
        setSearchText(e.detail.value)
    }

    const searchEntity1 = () => {
        const $or = prop.columns.filter(c => c.useSearch).map(c => ({
            [c.name]: { $regex: searchText.toLowerCase(), $options: 'i' }
        }))
        setObj({ ...obj, filter: { ...obj.filter, $or } })
    }

    useEffect(() => {
        if (prop.onSelectedItemsChanged)
            prop.onSelectedItemsChanged(prop.getDataOnSelect
                ? data.data.filter(d => selectedItems.includes(d._id))
                : selectedItems)
    }, [selectedItems])

    useEffect(() => {
        if (searchText !== '') searchEntity1()
        if (searchText === '' && data.filter)
            setObj({ ...obj, filter: { ...obj.filter, $or: undefined } })
    }, [searchText])

    useEffect(() => {
        if (prop.isRefetching) fetchData(obj)
        return () => prop.setRefetching(false)
    }, [prop.isRefetching])

    const changeLimit = (e) => {
        setObj({ ...obj, skip: 0, limit: e.target.value })
    }

    const prev = () => {
        setObj({
            ...obj,
            skip: data.skip - data.limit,
            limit: data.limit
        })
    }

    const next = () => {
        setObj({
            ...obj,
            skip: data.skip + data.limit,
            limit: data.limit
        })
    }

    useEffect(() => {
        fetchData(obj)
        setSelectedItems([])
    }, [obj])

    const clearData = () => {
        setData(initialData)
        setSelectedItems([])
        setSearchText('')
        setIntervalTime('');
    }

    useIonViewDidLeave(clearData)

    useIonViewDidEnter(() => {
        setObj({ ...obj })
    })

    useInterval(() => {
        if (intervalTime !== '') fetchData(obj)
    }, intervalTime)

    const onSelect = (dataId) => {
        if (dataId === 'all')
            return setSelectedItems(prev => prev.length > 0 ? [] : data.data.map(d => d._id));
        const added = selectedItems.find(s => s === dataId);
        setSelectedItems(prev => added
            ? selectedItems.filter(s => s !== dataId)
            : [...prev, dataId])
    }

    const setCustomFunc = (objectKey, objectValue) => {
        const col = prop.columns.find(c => c.name === objectKey);
        return col.customFunc
            ? col.customFunc(objectValue)
            : objectValue
    }

    const getFilterColumn = (column) => {

        const onChange = e => {
            const { name, value } = e.target;
            if (value !== '') setObj({
                ...obj, filter: {
                    ...data.filter,
                    [name]: value
                }
            })
            else setObj({ ...obj, filter: { [name]: undefined } })
        }

        const onIonChange = e => {
            const name = column.name
            const value = e.detail.value;
            if (value && value.length > 0) {
                setObj({
                    ...obj, filter: {
                        ...data.filter,
                        [name]: { $in: value }
                    }
                })
            } else setObj({ ...obj, filter: { [name]: undefined } })
        }
        if (column.name === 'createdAt') {
            return <FilterDateField 
                    obj={obj}
                    column={column}
                    setObj={setObj}
                    />
        }

        switch (column.filterOptions?.type) {
            case 'select':
                return <div className="td-div">
                    <span>{column.label}</span>
                    <select value={obj.filter[column.name] || ''}
                        onChange={onChange} name={column.name}>
                        <option value=''>Seçin</option>
                        {column.filterOptions.options.map((s, i) =>
                            <option key={i} value={s.name}>{s.translation}</option>)}
                    </select>
                </div>
            case 'select-multiple':
                return <div className="td-div" style={{ minHeight: 50 }}>
                    <Select label={column.label} selectProps={{
                        value: obj.filter[column.name]?.$in,
                        multiple: true,
                        onIonChange
                    }}>
                        {column.filterOptions.options.map((s, i) =>
                            <IonSelectOption key={i} value={s.name}>{s.translation}</IonSelectOption>
                        )}
                    </Select>
                </div>
            case 'entity-field':
                return <FilterObjectIdField 
                    column={column}
                    setObj={setObj}
                    />
            default:
                return <span>{column.label}</span>
        }
    }

    const changeSort = () => {
        setObj({
            ...obj, sort: {
                createdAt:
                    data.sort.createdAt === 'asc'
                        ? 'desc' : 'asc'
            }
        })
    }

    function exportXlsx() {
        let d = [
            {
                sheet: prop.entity,
                columns: prop.columns.map(c => ({ label: c.label, value: c.name })),
                content: data.data.filter(dd => selectedItems.includes(dd._id))
            }
        ]

        let settings = {
            fileName: 'Tezgel-' + prop.entity
        }
        xlsx(d, settings)
    }

    const hasError = (!data || data?.data.length === 0);


    return <div id="DataTable">
        <IonCard className="ion-no-margin">
            <IonCardContent>
                <IonRow>
                    <IonCol size={6}>
                        <IonSearchbar
                            style={{maxWidth: 200}}
                            value={searchText}
                            className="ion-no-margin"
                            placeholder="Axtar..."
                            onIonChange={onSearch}
                        />

                    </IonCol>
                    {!hasError ? <>
                        <IonCol size={6} className="ion-text-end">
                            <IonButton size="small" onClick={changeSort}>
                                <IonIcon src={data.sort.createdAt === 'asc' ? chevronDown : chevronUp} />
                            </IonButton>
                            <IonButton size="small" onClick={prev} disabled={data?.skip === 0}>
                                <IonIcon src={chevronBack} />
                            </IonButton>
                            <IonButton size="small" onClick={next} disabled={data?.data.length >= data?.count}>
                                <IonIcon src={chevronForward} />
                            </IonButton>
                            {prop.onAddButtonClick && <IonButton size="small" onClick={prop.onAddButtonClick} disabled={selectedItems.length !== 0}>
                                <IonIcon src={add} />
                            </IonButton>}
                            <IonButton size="small" onClick={exportXlsx} disabled={selectedItems.length === 0}>
                                <IonIcon src={documentSharp} />
                            </IonButton>
                            {!prop.hideRefresh && <IonButton size="small" onClick={() => prop.setRefetching(true)}>
                                <IonIcon src={refresh} />
                            </IonButton>}
                        </IonCol>
                        <IonCol className="ion-margin-vertical">
                            <strong>Göst. <select onChange={changeLimit}>
                                {[10, 20, 30, 50, 100].map((o, i) => <option value={o} key={i}>{o}</option>)}
                            </select> / {data?.count} </strong>
                            |
                            <strong> Interval: </strong>
                            <select onChange={e => setIntervalTime(e.target.value)} value={intervalTime}>
                                <option value="">Seçin</option>
                                {intervalOptions.map((o, i) => <option value={o.value} key={i}>
                                    {o.label}
                                </option>)}
                            </select>
                            {intervalTime !== '' && <div className="interval-circle blink_me" />}
                        </IonCol>
                        <IonCol size={12}>
                            {prop.buttons?.map((b, i) => <IonButton
                                {...b}
                                disabled={b.disableCondition}
                                key={i} className="action-button" size="small">
                                {b.label}
                            </IonButton>)}
                        </IonCol>
                    </> : <IonButton fill="clear" onClick={() => setObj(initialObj)} className="ion-margin-start">
                        Heçnə tapılmadı
                    </IonButton>}

                </IonRow>

                <table className="table table-sm table-responsive ion-margin-top">
                    <thead style={{ backgroundColor: 'var(--ion-color-light)' }}>
                        <tr>
                            <th>
                                <IonCheckbox
                                    onClick={() => onSelect('all')}
                                    checked={selectedItems.length === data.data.length}
                                    indeterminate={selectedItems.length > 0 && selectedItems.length < data.data.length}
                                />
                            </th>
                            {prop.showButton && <th></th>}

                            {prop.columns.map((c, i) => <th key={i}>
                                {getFilterColumn(c)}
                            </th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {data.data.map((d, i) => <tr className={selectedItems.includes(d._id) ? 'selected' : ''} key={i}>
                            <td>
                                <IonCheckbox
                                    checked={selectedItems.includes(d._id)}
                                    onClick={() => onSelect(d._id)} />
                            </td>
                            {prop.showButton && <td style={{ paddingTop: 5 }}>
                                <IonButton
                                    fill="clear"
                                    id="alignment-button"
                                    size="small"
                                    onClick={(e) => {
                                        setSelectedItems([d._id])
                                        present({
                                            event: e.nativeEvent,
                                        })
                                    }
                                    }
                                    className="ion-no-margin">
                                    <IonIcon icon={menuSharp} />
                                </IonButton>
                            </td>}

                            {prop.columns.map(c => c.name).map((oK, i) => <td key={i}>
                                <div>{setCustomFunc(oK, get(d, oK))}</div>

                            </td>)}
                        </tr>)}
                    </tbody>
                </table>
            </IonCardContent>
        </IonCard>

    </div>
}

DataTable.defaultProps = {
    showButton: true
}

export default DataTable;