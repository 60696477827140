import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCol, IonContent, IonFooter, IonInput, IonItem, IonLabel, IonRow, IonSelectOption, IonToolbar } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import useApi from "../../hooks/useApi";
import useToast from "../../hooks/useToast";
import { createEntity, updateEntity, searchEntity, updateCategory, createCategory } from "../../services";
import Input from "../Input";
import Modal from "../Modal";
import uniqid from 'uniqid'
import { createSlug } from "../../utils/helpers";
import ImageUploader from "../ImageUploader";

const initialValue = {
    name: '',
    subCategories: [{
        name: 'Yeni Kateqoriya',
        slug: 'new',
        _id: uniqid()
    }],
    picUrl: ''
}

export default function CategoryDetail({ isOpen, setOpen, initialCategoryId, ...rest }) {
    const [formData, setFormData] = useState(initialValue);
    const { categories } = useContext(AppContext)
    const [api] = useApi()
    const [toast] = useToast()

    const onSubmit = async () => {
        const newFormData = {
            ...formData,
            subCategories: formData.subCategories.map(s => {
                if (s.slug === 'new') {
                    s._id = undefined;
                    s.slug = createSlug(s.name + '-' + uniqid());
                }
                return s;
            })
        }
        try {
            if (initialCategoryId) {
                await api(updateCategory, {
                    ...newFormData,
                    slug: createSlug(formData.name + '-' + uniqid())
                });
            } else {
                await api(createCategory, {
                    ...newFormData,
                    slug: createSlug(formData.name + '-' + uniqid())
                })
            }
            setOpen(false)
        } catch (error) {

        }
    }

    const onIonChange = e => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }

    const fetchCategory = async () => {
        try {
            const data = await api(searchEntity, { entity: 'category', filter: { _id: initialCategoryId } })
            if (data.count !== 1) return toast({ message: 'Kateqoriya tapilmadi.' })
            setFormData(data.data[0])
        } catch (error) {
            console.log(error.message)
        }
    }
    

    const removeSubCategory = subCategoryId => {
        if (formData.subCategories.length <= 1) return toast({ message: 'Minimum 1 alt kateqoriya zəruridir.' })
        setFormData({
            ...formData,
            subCategories: formData.subCategories.filter(sC => sC._id !== subCategoryId)
        })
    }

    const addSubCategory = () => setFormData({
        ...formData,
        subCategories: [{
            name: 'Yeni Kateqoriya',
            slug: 'new',
            _id: uniqid()
        }, ...formData.subCategories,]
    })

    useEffect(() => {
        if (initialCategoryId) fetchCategory()
        else setFormData(initialValue)
    }, [initialCategoryId])

    return <Modal title="Kateqoriya detalları"
        isOpen={isOpen}
        {...rest}
        setOpen={setOpen}>

        <IonContent className="ion-padding">
            <IonRow>
                <IonCol>
                    <Input
                        inputProps={{
                            onIonChange,
                            value: formData.name,
                            name: 'name'
                        }}
                        label="Ad"
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size={12}>
                    <ImageUploader
                        images={[formData.picUrl]}
                        setImages={e => setFormData({ ...formData, picUrl: e.length > 0 ? e[0] : '' })}
                    />
                </IonCol>
            </IonRow>
            <IonCard>
                <IonRow>
                    <IonCol className="ion-padding-start ion-margin-start">
                        <h5>Alt Kateqoriyalar</h5>
                    </IonCol>
                    <IonCol className="ion-text-end">
                        <IonButton size="small" onClick={addSubCategory}>Yeni artır</IonButton>
                    </IonCol>
                </IonRow>
                <IonCardContent>
                    {formData.subCategories.map(s => <IonItem key={s._id}>
                        <IonInput onIonChange={e => setFormData({
                            ...formData,
                            subCategories: formData.subCategories.map(sC => {
                                if (s._id === sC._id) sC.name = e.target.value;
                                return sC;
                            })
                        })} value={s.name} />
                        <IonButton slot="end" color="danger" onClick={() => removeSubCategory(s._id)}>
                            Sil
                        </IonButton>
                    </IonItem>)}
                </IonCardContent>
            </IonCard>
        </IonContent>
        <IonFooter>
            <IonToolbar>
                <IonButton onClick={onSubmit} expand="block">
                    Təsdiqlə
                </IonButton>
            </IonToolbar>
        </IonFooter>
    </Modal>
}