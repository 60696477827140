import { IonPage, IonContent, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonBadge, IonMenuButton, IonFab, IonFabButton } from "@ionic/react";
import { useState } from "react";
import { ChangeState, CourierDetail, DataTable } from "..";
import Header from "../Header";
import courierStates from "../../data/courierStates";
import useAuthRequired from "../../hooks/useAuthRequired";
import { dateTime, price } from "../../utils/format";


const CourierBadge = ({ data }) => {
    const stateValue = courierStates.find(o => o.name === data)?.translation
    return <IonBadge>{stateValue}</IonBadge>
}

const columns = [
    {
        label: 'SID',
        name: 'sid',
        useSearch: true
    },
    {
        label: 'Ad',
        name: 'name',
        useSearch: true
    },
    {
        label: 'Nömrə',
        name: 'phoneNumber',
        useSearch: true
    },
    {
        label: 'Status',
        name: 'state',
        useSearch: false,
        customFunc: data => <CourierBadge data={data} />,
        filterOptions: {
            type: 'select',
            options: courierStates
        }
    },
    {
        label: 'Tarix',
        name: 'createdAt',
        customFunc: data => <span>{dateTime(data)}</span>
    }
]

export default function CouriersTable({onSelectedItemsChangedEvent, ...rest }) {
    const [isCourierDetailOpen, setCourierDetailOpen] = useState(false);
    const [selectedCouriers, setSelectedCouriers] = useState([]);
    const [isRefetching, setRefetching] = useState(false);
    const [isChangeStateOpen, setChangeStateOpen] = useState(false);

    function onSelectedItemsChanged(items) {
        setSelectedCouriers(items);
        onSelectedItemsChangedEvent && onSelectedItemsChangedEvent(items)
    }
    function onStateSuccess() {
        setRefetching(true)
    }
    const tableButtons = [
        {
            label: 'Düzəliş et',
            onClick: e => setCourierDetailOpen(true),
            disableCondition: selectedCouriers.length !== 1
        },
        {
            label: 'Status Dəyiş',
            onClick: e => setChangeStateOpen(true),
            disableCondition: selectedCouriers.length === 0
        }
    ]
    return <>
        <DataTable
            columns={columns}
            entity={'courier'}
            isRefetching={isRefetching}
            setRefetching={setRefetching}
            onSelectedItemsChanged={onSelectedItemsChanged}
            buttons={tableButtons}
            onAddButtonClick={() => setCourierDetailOpen(true)}
            {...rest}
        />
        <CourierDetail
            onDidDismiss={() => setRefetching(true)}
            isOpen={isCourierDetailOpen}
            setOpen={setCourierDetailOpen}
            initialCourierId={selectedCouriers.length === 1 && selectedCouriers[0]}
        />
        <ChangeState
            isOpen={isChangeStateOpen}
            setOpen={setChangeStateOpen}
            entity={'courier'}
            onSuccess={onStateSuccess}
            states={courierStates}
            entityIds={selectedCouriers}
        />
    </>
}


/* 
server {
        listen 80;
        listen [::]:80;

        root /var/www/TezgelAdminServer;

        server_name admin-api.tezgel.az www.admin-api.tezgel.az;

        location / {
            proxy_pass http://localhost:3001;
            proxy_http_version 1.1;
            proxy_set_header Upgrade $http_upgrade;
            proxy_set_header Connection 'upgrade';
            proxy_set_header Host $host;
            proxy_cache_bypass $http_upgrade;
        }
}
*/