import { IonButton, IonCol, IonContent, IonFooter, IonRow, IonToolbar } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import useApi from "../../hooks/useApi";
import useToast from "../../hooks/useToast";
import { SelectorMap } from "..";
import { createCourier, searchEntity, updateCourier } from "../../services";
import Input from "../Input";
import Modal from "../Modal";
import { Map, Placemark } from "react-yandex-maps";
import pin from '../../assets/images/courier-pin.svg'

const initialValue = {
    _user: '',
    name: '',
    sid: '',
    phoneNumber: '',
    location: {
        type: 'Point',
        coordinates: [0, 0]
    }
}

export default function CourierDetail({ isOpen, setOpen, initialCourierId, ...rest }) {
    const [formData, setFormData] = useState(initialValue);
    const [api, isLoading] = useApi()
    const { setCouriers } = useContext(AppContext);
    const [toast] = useToast()

    const onSubmit = async () => {
        try {
            if (initialCourierId) {
                const { instance: courier } = await api(updateCourier, formData);
                setCouriers(prev => prev.map(p => p._id === courier._id ? courier : p))
            } else {
                const { instance: courier } = await api(createCourier, formData)
                setCouriers(prev => [...prev, courier])
            }
            setOpen(false)
        } catch (error) {

        }
    }

    const onIonChange = async e => {
        const { name, value } = e.target;
        if (name === 'phoneNumber' && value.length === 13) {
            try {
                const { data, count } = await searchEntity({
                    entity: 'user',
                    filter: {phoneNumber: value, role: 'courier'}
                })
                if (count === 1) {
                    setFormData(prev => ({
                        ...prev, 
                       _user: data[0]._id,
                       name: data[0]?.displayName || ''
                   }))
                } else {
                    throw new Error('')
                }
            } catch (error) {
                toast({message: 'Bu nomre ile kuryer tapilmadi'})
            }
        }
        setFormData(prev => ({ ...prev, [name]: value }))
    }

    const fetchCourier = async () => {
        try {
            const data = await api(searchEntity, { entity: 'courier', filter: { _id: initialCourierId } })
            if (data.count !== 1) return toast({ message: 'Kuryer tapilmadi.' })
            setFormData(data.data[0])
        } catch (error) {
            console.log(error.message)
        }
    }
    // +994505025052
    useEffect(() => {
        if (initialCourierId) fetchCourier()
        else setFormData(initialValue)
    }, [initialCourierId])

    return <Modal title="Kuryer detalları"
        isOpen={isOpen}
        {...rest}
        setOpen={setOpen}>

        <IonContent>
            <Map
                width={'100%'}
                height={'100%'}
                state={{ center: formData.location.coordinates, zoom: 16 }}
                {...rest}
            >
                <Placemark
                    geometry={formData.location.coordinates}
                    options={{
                        iconLayout: 'default#image',
                        iconImageHref: pin,
                        iconImageSize: [60, 60],
                        iconOffset: [-20, -20],
                    }}
                />

            </Map>
        </IonContent>
        <IonFooter>
            <IonRow>
                <IonCol>
                    <Input
                        inputProps={{
                            onIonChange,
                            value: formData.phoneNumber,
                            name: 'phoneNumber'
                        }}
                        label="Nömrə"
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <Input
                        inputProps={{
                            onIonChange,
                            value: formData.sid,
                            name: 'sid'
                        }}
                        label="Kuryer SID"
                    />
                </IonCol>
                <IonCol>
                    <Input
                        inputProps={{
                            onIonChange,
                            readOnly: true,
                            value: formData.name,
                            name: 'name'
                        }}
                        label="Ad"
                    />
                </IonCol>
            </IonRow>

            <IonToolbar>
                <IonButton disabled={isLoading} onClick={onSubmit} expand="block">
                    Təsdiqlə
                </IonButton>
            </IonToolbar>
        </IonFooter>
    </Modal>
}