import { IonAccordion, IonAccordionGroup, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonFooter, IonIcon, IonItem, IonLabel, IonList, IonPage, IonRow, IonTitle, IonToolbar, useIonViewDidEnter, useIonViewDidLeave } from '@ionic/react';
import { Map, Placemark } from 'react-yandex-maps';
import { CourierDetail, CouriersTable, Header, InnerItem, LeafletMap, OrdersTable, WarehousesTable } from '../../components';
import useAuthRequired from '../../hooks/useAuthRequired';
import usePosition from '../../hooks/usePosition';
import Orders from '../Orders';
import './styles.css';
import pin from '../../assets/images/courier-pin.svg'
import React, { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../App';
import io from "socket.io-client";
import { getValue } from '../../utils/storage';
import { attachCourierToOrder, changeStateEntity, searchEntity, updateOrder } from '../../services';
import Couriers from '../Couriers';
import { chevronDown, chevronUp } from 'ionicons/icons';
import * as EsriGeocoder from "esri-leaflet-geocoder"
import { ESRI_API_KEY, SOCKET_URL } from "../../constants";
import L from 'leaflet'
import houseIcon from '../../assets/images/house-pin.svg';
import warehouseIcon from '../../assets/images/warehouse-pin.svg';
import useToast from '../../hooks/useToast';
import { sortByDistance } from '../../utils/helpers';
import useApi from '../../hooks/useApi';


/* 

 - Kuryer App
  -

*/


const getCourierPin = (courierState) => {
  switch (courierState) {
    case 'idle':
      return "green"
    case 'busy':
      return "red";
    default:
      return ''
  }
}


const accordions = [
  {
    label: 'Sifarişlər',
    value: 'orders'
  },
  {
    label: 'Kuryerlər',
    value: 'couriers'
  },
  {
    label: 'Depolar',
    value: 'warehouses'
  }
]



const Home = () => {
  const { couriers, setCouriers, warehouses, setWarehouses } = useContext(AppContext);
  const [orderMarkers, setOrderMarkers] = useState([]);
  const [courierMarkers, setCourierMarkers] = useState({});
  const [map, setMap] = useState(null);
  const [position] = usePosition();
  const [toast] = useToast()
  const [api] = useApi()
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [selectedCouriers, setSelectedCouriers] = useState([]);
  const [selectedWarehouses, setSelectedWarehouses] = useState([]);

  const [tables, setTables] = useState([{
    entity: 'order',
    tableComponent: OrdersTable,
    isOpen: true,
    title: 'Sifarişlər',
    additionalButtons: [],
    isRefetchingInitialValue: false
  }, {
    entity: 'courier',
    tableComponent: CouriersTable,
    isOpen: false,
    title: 'Kuryerlər'
  }, {
    entity: 'warehouse',
    tableComponent: WarehousesTable,
    isOpen: false,
    title: 'Depolar'
  }]
  )

  useAuthRequired()

  const socketRef = useRef()

  const toggleTable = (entity, isOpen) => {
    setTables(prev => prev.map(t => {
      if (t.entity === entity) t.isOpen = isOpen
      return t;
    }))
  }

  useEffect(() => {
    if (map) {
      for (let i = 0; i < orderMarkers.length; i++) {
        map.removeLayer(orderMarkers[i])
      }

      setOrderMarkers(selectedOrders.map(d => {
        var houseIconn = L.icon({
          iconUrl: houseIcon,
          iconSize: [60, 60],
          iconAnchor: [30, 57],
        });
        if (selectedOrders.length === 1) map.panTo(d.address.location.coordinates);

        const mark = L.marker(d.address.location.coordinates, {
          icon: houseIconn
        })
        mark.addTo(map)
        return mark
      }))
    }
  }, [selectedOrders])


  const attachCourierToOrder1 = async () => {
    try {
      let _courier;
      if (selectedCouriers.length === 1) {
        _courier = selectedCouriers[0]._id;
      } else {
        _courier = sortByDistance(couriers, selectedOrders[0].address.location.coordinates)
          .find(c => c.state === 'idle')?._id
      }
      if (!_courier) return toast({ message: 'Kuryer tapılmadı.' })
      await api(attachCourierToOrder, ({
        _id: selectedOrders[0]._id,
        _courier
      }));

      map.panTo(couriers.find(c => c._id === _courier).location.coordinates)
    } catch (error) {
      toast({ message: 'Xəta baş verdi.' })
    }
  }

  useEffect(() => {
    if (selectedOrders.length === 1)
      setTables(prev => prev.map(p => {
        if (p.entity === 'order') {
          p.additionalButtons = [{
            label: 'Kuryer artır',
            onClick: attachCourierToOrder1,
            disableCondition: false
          }]
        }
        return p
      }))
    else setTables(prev => prev.map(p => {
      if (p.entity === 'order') {
        p.additionalButtons = [{
          label: 'Kuryer artır',
          onClick: attachCourierToOrder1,
          disableCondition: true
        }]
      }
      return p
    }))
  }, [selectedOrders, selectedCouriers])

  useEffect(() => {
    if (selectedCouriers.length === 1) {
      const courier = couriers.find(c => c._id === selectedCouriers[0]._id)
      if (!courier) return toast({ message: 'Kuryer tapılmadı.' })
      map.panTo(courier.location.coordinates)
    }
  }, [selectedCouriers])

  useEffect(() => {
    if (selectedWarehouses.length === 1) {
      const warehouse = warehouses.find(c => c._id === selectedWarehouses[0]._id)
      if (!warehouse) return toast({ message: 'Depo tapılmadı.' })
      map.panTo(warehouse.location.coordinates)
    }
  }, [selectedWarehouses])

  const onSelectedItemsChanged = async (entity, selectedItems) => {
    let setFunction;
    switch (entity) {
      case 'order':
        setFunction = setSelectedOrders
        break;
      case 'courier':
        setFunction = setSelectedCouriers
        break;
      default:
        setFunction = setSelectedWarehouses
        break;
    }
    setFunction(selectedItems)
  }

  const renewCourierPosition = data => {
    data = JSON.parse(data)
    setCouriers(prev => prev.map(p => {
      if (p._user === data._user && data.position) {
        if (courierMarkers[p._user]) map.removeLayer(courierMarkers[p._user]);
        p.location.coordinates = data.position;
        p.state = data.state;
      }
      return p
    }))
  }

  useEffect(() => {
    if (map) {
      const searchControl = EsriGeocoder.geosearch({
        // position: "topright",
        placeholder: "Ünvan və ya yer axtarın.",
        useMapBounds: false,
        providers: [
          EsriGeocoder.arcgisOnlineProvider({
            // API Key to be passed to the ArcGIS Online Geocoding Service
            apikey: ESRI_API_KEY
          })
        ]
      }).addTo(map);

      EsriGeocoder.reverseGeocode({
        apikey: ESRI_API_KEY
      })
        .latlng(position).run(function (error, result, response) {
          if (result.address) {
            // setDirections(result.address.LongLabel)
          }
        });

      const results = L.layerGroup().addTo(map);

      searchControl.on("results", (data) => {
        results.clearLayers();
        for (let i = data.results.length - 1; i >= 0; i--) {
          // setDirections(data.results[i].properties.LongLabel)
          // mark.setLatLng(new L.LatLng(data.results[i].latlng.lat, data.results[i].latlng.lng), { draggable: 'true' });
          // mark.bindPopup(`<p>${data.results[i].properties.LongLabel}</p>`);
          // mark.openPopup();
        }
      });
    }
  }, [map])

  useEffect(() => {
    if (couriers.length > 0 && map) {
      for (let i = 0; i < couriers.length; i++) {
        const courierPinColor = getCourierPin(couriers[i].state);
        const icon = new L.DivIcon({
          className: `label-icon ${courierPinColor}`,
          html: `<div class="icon-span">${couriers[i].sid}</div>
          <div class="arrow-down"></div>`

        })
        EsriGeocoder.reverseGeocode({
          apikey: ESRI_API_KEY
        })
          .latlng(couriers[i].location.coordinates).run(function (error, result, response) {
            if (result.address) {

              let title = `Kuryer SID: ${couriers[i].sid}\nKuryer Adı: ${couriers[i].name}\nKuryer Nömrəsi: ${couriers[i].phoneNumber}\n---\n`
              const addressKeys = Object.keys(result.address)
              for (let i = 0; i < addressKeys.length; i++) {
                const key = addressKeys[i];
                title += `${key}: ${result.address[key]}\n`
              }

              const mark = L.marker(couriers[i].location.coordinates, {
                icon,
                title
              }).addTo(map).on('click', function (e) {
                // open 
              });

              setCourierMarkers(prev => ({ ...prev, [couriers[i]._user]: mark }))

              map.addLayer(mark)
            }
          });

      }
    }
  }, [couriers, map])


  useEffect(() => {
    if (warehouses.length > 0 && map) {
      for (let i = 0; i < warehouses.length; i++) {
        const warehouseIconn = L.icon({
          iconUrl: warehouseIcon,
          iconSize: [60, 60],
          iconAnchor: [30, 57],
        });
        EsriGeocoder.reverseGeocode({
          apikey: ESRI_API_KEY
        })
          .latlng(warehouses[i].location.coordinates).run(function (error, result, response) {
            if (result.address) {
              let title = `Adı: ${warehouses[i].name}\nNömrəsi: ${warehouses[i].phoneNumber}\n---\n`
              const addressKeys = Object.keys(result.address)
              for (let i = 0; i < addressKeys.length; i++) {
                const key = addressKeys[i];
                title += `${key}: ${result.address[key]}\n`
              }

              const mark = L.marker(warehouses[i].location.coordinates, {
                icon: warehouseIconn,
                title
              }).addTo(map);

              map.addLayer(mark)
            }
          });

      }
    }
  }, [warehouses, map])


  const courierAcceptedOrder = data => {
    data = JSON.parse(data)
    alert(data.order.address.directions + '  unvanindaki sifarisi ' 
    + data.courierSid + ' nomreli kuryer qebul etdi.')
  }

  const courierDeclinedOrder = data => {
    data = JSON.parse(data)
    alert(data.order.address.directions + '  unvanindaki sifarisi ' 
    + data.courierSid + ' nomreli kuryer legv etdi.')
  }


  useIonViewDidEnter(() => {
    socketRef.current = io(SOCKET_URL, {
    });
    socketRef.current.on('courier-position', renewCourierPosition)
    socketRef.current.on('courier-accepted-order', courierAcceptedOrder)
    socketRef.current.on('courier-declined-order', courierDeclinedOrder)
  })

  useIonViewDidLeave(() => {
    socketRef.current.off('courier-position')
    socketRef.current.off('courier-accepted-order')
    socketRef.current.off('courier-declined-order')
    socketRef.current.disconnect();
  })

  return (
    <IonPage id="Home">
      <Header title="Əsas Səhifə" />
      <IonContent className="Home__content ion-padding">
        <IonRow style={{ height: '100%' }}>
          <IonCol size={7}>
            {tables.map(t => <IonCard key={t.entity} className='table-card ion-no-margin ion-margin-bottom'>
              <IonCardHeader color='light' onClick={() => toggleTable(t.entity, !t.isOpen)}>
                <IonCardTitle>
                  {t.title}
                </IonCardTitle>
                <IonIcon icon={t.isOpen ? chevronUp : chevronDown} className="button-icon" />
              </IonCardHeader>
              <IonCardContent style={{ display: t.isOpen ? 'block' : 'none' }} className='datatable'>
                <InnerItem
                  inner={t.tableComponent}
                  getDataOnSelect
                  onSelectedItemsChangedEvent={selectedItems => onSelectedItemsChanged(t.entity, selectedItems)}
                  {...t}
                />
              </IonCardContent>
            </IonCard>)}
          </IonCol>
          <IonCol size={5}>
            {(position) && <IonCard className='ion-no-margin' style={{ height: '100%' }}>
              <LeafletMap setMap={setMap} position={position} />
            </IonCard>}
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default Home;
