const API_URL = 'http://localhost:3000'
const SOCKET_URL = 'https://admin-api.tezgel.az'
const ONESIGNAL_ID = '5ce4651f-23e3-48e7-a69a-a75b3e8d0f35'
const ESRI_API_KEY = 'AAPKb981aebce15046eb9e11509ed32477acfM8VrUy1i_D70t2dv-9sri7umo75NdqZdo6N1sxPMkNXzNFK-tQY1hPHeq6pQsXj'
const ESRI_BASEMAP_ENUM = "ArcGIS:Navigation";



export {
    API_URL,
    SOCKET_URL,
    ONESIGNAL_ID,
    ESRI_API_KEY,
    ESRI_BASEMAP_ENUM
}