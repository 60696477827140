export default [
    {
        name: 'created',
        translation: 'Sırada'
    },
    {
        name: 'getting-ready',
        translation: 'Hazırlanır'
    },
    {
        name: 'on-way',
        translation: 'Yolda'
    },
    {
        name: 'at-address',
        translation: 'Adresdə'
    },
    {
        name: 'cancelled',
        translation: 'Ləğv edilib'
    },
    {
        name: 'delivered',
        translation: 'Təhvil edilib'
    },
    {
        name: 'hot',
        translation: 'Qaynar'
    },
]