import useToast from "./useToast";
import { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { getValue } from "../utils/storage";
import jwt_decode from "jwt-decode";
import { useIonViewWillEnter } from "@ionic/react";

function isTokenExpired(token) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }

export default function useAuthRequired() {
    const [toast] = useToast();
    const history = useHistory();

    useIonViewWillEnter(() => {
        (async () => {
            try {
                const token = await getValue('@token');
                if (token) {
                    const decodedToken = jwt_decode(token);
                    if (decodedToken.role !== 'admin' && decodedToken.role !== 'operator') throw new Error('Bu səhifə üçün səlahiyyətiniz yoxdur!')
                    if (isTokenExpired(token)) throw new Error('Yeniden login olun')
                } else {
                    window.location = '/auth'
                }
            } catch (error) {
                toast({message: error.message})
                window.location = '/auth'
            }
        })()
    })


}
