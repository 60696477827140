export default [
    {
        name: 'admin',
        translation: 'Moderator'
    },
    {
        name: 'operator',
        translation: 'Operator'
    },
    {
        name: 'courier',
        translation: 'Kuryer'
    },
    {
        name: 'partner',
        translation: 'Əməkdaş'
    },
    {
        name: 'customer',
        translation: 'Müştəri'
    },
    {
        name: 'ban',
        translation: 'Banlanmış'
    },
]