import { IonPage, IonContent, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonBadge, IonMenuButton, IonFab, IonFabButton } from "@ionic/react";
import { useState } from "react";
import { WarehouseDetail, DataTable, WarehousesTable } from "../../components";
import Header from "../../components/Header";
import warehouseStates from "../../data/warehouseStates";
import warehouseTypes from "../../data/warehouseTypes";
import useAuthRequired from "../../hooks/useAuthRequired";
import { dateTime, price } from "../../utils/format";


export default function Warehouses() {
    useAuthRequired()
 
    return <IonPage id="Warehouses">
        <Header title="Depo, Mağaza və Restoranlar" />
        <IonContent className="ion-padding">
           <WarehousesTable/>
        </IonContent>
       
    </IonPage>
}