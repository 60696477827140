import { IonButton, IonCol, IonContent, IonFooter, IonRow, IonSelectOption, IonToolbar } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import useToast from "../../hooks/useToast";
import { changeStateEntity } from "../../services";

import Modal from "../Modal";
import Select from "../Select";


export default function ChangeState({ onSuccess, states, entity, isOpen, setOpen, entityIds, ...rest }) {
    const [api] = useApi()
    const [toast] = useToast()
    const [state, setState] = useState('');

    const onSubmit = async () => {
        try {
            await api(changeStateEntity, {state, entity, entityIds});
            setState('')
            onSuccess()
            setOpen(false)
        } catch (error) {
            
        }
    }

    return <Modal title="Status Dəyiş"
        isOpen={isOpen}
        setOpen={setOpen}
        initialBreakpoint={0.3}
        breakpoints={[0.3]}
        {...rest}>

        <IonContent className="ion-padding">
            <Select
                selectProps={{
                    value: state,
                    name: 'state',
                    onIonChange: e => setState(e.detail.value)
                }}
                label="Yeni statusu seçin"
            >
                {states.map(s => (<IonSelectOption key={s.name} value={s.name}>
                    {s.translation}
                </IonSelectOption>))}
            </Select>
            <IonButton style={{height: '50px', margin: 0, marginTop: 10}} onClick={onSubmit} expand="block">
                Təsdiqlə
            </IonButton>
        </IonContent>

    </Modal>
}