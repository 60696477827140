import { IonPage, IonContent, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonBadge, IonMenuButton, IonFab, IonFabButton } from "@ionic/react";
import { useState } from "react";
import { WarehouseDetail, DataTable } from "..";
import Header from "../Header";
import warehouseStates from "../../data/warehouseStates";
import warehouseTypes from "../../data/warehouseTypes";
import useAuthRequired from "../../hooks/useAuthRequired";
import { dateTime, price } from "../../utils/format";

const WarehouseBadge = ({ data }) => {
    const stateValue = warehouseStates.find(o => o.name === data).translation
    return <IonBadge>{stateValue}</IonBadge>

}

const WarehouseTypeBadge = ({ data }) => {
    const stateValue = warehouseTypes.find(o => o.name === data).translation
    return <IonBadge>{stateValue}</IonBadge>

}


const columns = [
    {
        label: 'Ad',
        name: 'name',
        useSearch: true
    },
    {
        label: 'Nömrə',
        name: 'phoneNumber',
        useSearch: true
    },
    {
        label: 'Status',
        name: 'state',
        useSearch: false,
        customFunc: data => <WarehouseBadge data={data} />,
        filterOptions: {
            type: 'select',
            options: warehouseStates
        }
    },
    {
        label: 'Növ',
        name: 'type',
        useSearch: false,
        customFunc: data => <WarehouseTypeBadge data={data} />,
        filterOptions: {
            type: 'select',
            options: warehouseTypes
        }
    },
    {
        label: 'Tarix',
        name: 'createdAt',
        customFunc: data => <span>{dateTime(data)}</span>
    }
]

export default function WarehousesTable({ onSelectedItemsChangedEvent, ...rest }) {
    const [isWarehouseDetailOpen, setWarehouseDetailOpen] = useState(false);
    const [selectedWarehouses, setSelectedWarehouses] = useState([]);
    const [isRefetching, setRefetching] = useState(false);

    function onSelectedItemsChanged(items) {
        setSelectedWarehouses(items)
        onSelectedItemsChangedEvent && onSelectedItemsChangedEvent(items)
    }

    const tableButtons = [
        {
            label: 'Düzəliş et',
            onClick: e => setWarehouseDetailOpen(true),
            disableCondition: selectedWarehouses.length !== 1
        },
        {
            label: 'Status Dəyiş',
            onClick: e => console.log('e is product ID'),
            disableCondition: selectedWarehouses.length === 0
        }
    ]

    return <>
        <DataTable
            columns={columns}
            entity={'warehouse'}
            isRefetching={isRefetching}
            setRefetching={setRefetching}
            onSelectedItemsChanged={onSelectedItemsChanged}
            buttons={tableButtons}
            onAddButtonClick={() => setWarehouseDetailOpen(true)}
            {...rest}
        />
        <WarehouseDetail
            onDidDismiss={() => setRefetching(true)}
            isOpen={isWarehouseDetailOpen}
            setOpen={setWarehouseDetailOpen}
            initialWarehouseId={selectedWarehouses.length === 1 && selectedWarehouses[0]}
        />
    </>

}