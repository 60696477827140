import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonText,
  IonThumbnail,
} from '@ionic/react';

import { useLocation } from 'react-router-dom';
import { appsSharp, archiveOutline, archiveSharp, chatboxEllipsesOutline, bag, bagSharp, beerSharp, bookmarkOutline, carOutline, carSharp, cashSharp, checkboxSharp, cogSharp, constructSharp, documentTextSharp, fastFoodSharp, giftSharp, heartOutline, heartSharp, home, homeSharp, logOutSharp, mailOutline, mailSharp, man, newspaperSharp, paperPlaneOutline, paperPlaneSharp, person, storefront, storefrontSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
import './styles.css';
import Logo from '../Logo';
import ProfilePhoto from '../ProfilePhoto';
import { useContext, useEffect, useRef } from 'react';
import { AppContext } from '../../App';
import { menuController } from '@ionic/core';


const appPages = [
  {
    title: 'Əsas Səhifə',
    url: '/home',
    icon: homeSharp
  },
  {
    title: 'İstifadəçilər',
    url: '/users',
    icon: person
  },
  {
    title: 'Sifarişlər',
    url: '/orders',
    icon: bagSharp
  },
  {
    title: 'Kuryerlər',
    url: '/couriers',
    icon: carSharp
  },
  {
    title: 'Ödənişlər',
    url: '/payments',
    icon: documentTextSharp
  },
  {
    title: 'Depo, Mağaza və Restoranlar',
    url: '/warehouses',
    icon: storefrontSharp
  },
  {
    title: 'Müraciətlər',
    url: '/tickets',
    icon: chatboxEllipsesOutline
  }

];


const Menu = (props) => {
  const { user, logOut } = useContext(AppContext)
  const location = useLocation();

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader><Logo /></IonListHeader>
          <IonItem className='ion-margin-vertical' lines="none">
            <IonThumbnail slot='start'>
              <ProfilePhoto />
            </IonThumbnail>
            <IonLabel>
              <IonText>
                <p><strong>{user?.displayName || 'Ad yazilmayib'} | {user?.role}</strong></p>
                {user?.phoneNumber}
              </IonText>
            </IonLabel>
            <IonButton onClick={logOut} slot="end" fill="clear">
              <IonIcon slot="icon-only" icon={logOutSharp} />
            </IonButton>
          </IonItem>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon slot="start" icon={appPage.icon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
