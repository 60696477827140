import { IonPage, IonContent, IonBadge } from "@ionic/react";
import { useState } from "react";
import { ChangeState, DataTable, Header } from "../../components";
import TicketModal from "../../components/TicketModal";
import ticketStates from "../../data/ticketStates";
import useAuthRequired from "../../hooks/useAuthRequired";
import { dateTime } from "../../utils/format";
import "./styles.css"

const TicketBadge = ({ data }) => {
    switch (data) {
        case 'created':
            return <IonBadge color="warning">Yeni Müraciət</IonBadge>
        case 'inwork':
            return <IonBadge color="danger">Cavab Verildi</IonBadge>
        case 'open':
            return <IonBadge color="danger">Yeni Mesaj</IonBadge>
        case 'finish':
            return <IonBadge color="danger">Müraciət Bitib</IonBadge>
        default:
            return <IonBadge color="warning">Yeni Müraciət</IonBadge>
    }
}
const columns = [
    {
        label: 'Başlıq',
        name: 'subject',
        useSearch: true,
    },
    {
        label: 'Status: ',
        name: 'state',
        useSearch: true,
        customFunc: data => <TicketBadge data={data} />,
        filterOptions: {
            type: 'select',
            options: ticketStates
        }
    },
    {
        label: 'Tarix',
        name: 'createdAt',
        customFunc: data => <span>{dateTime(data)}</span>
    }
]



export default function Tickets() {
    useAuthRequired()
    const [isChangeStateOpen, setChangeStateOpen] = useState(false);
    const [isRefetching, setRefetching] = useState(false);
    const [selectedTickets, setSelectedTickets] = useState([]);
    const [ticketDetail, setTicketDetail] = useState([])
    const [isTicketOpen, setTicketOpen] = useState(false)
    
    function onSelectedItemsChanged(items) {
        setSelectedTickets(items)
        if (items.length === 1) {
            setTicketDetail(items[0])
        }
    }

    function onStateSuccess() {
        setRefetching(true)
    }
    const tableButtons = [
        {
            label: 'Müraciəti Cavabla',
            onClick: e => setTicketOpen(true),
            disableCondition: selectedTickets.length !== 1
        },
        {
            label: 'Status Dəyiş',
            onClick: e => setChangeStateOpen(true),
            disableCondition: selectedTickets.length === 0
        },
        // {
        //     label: 'Chat',
        //     onClick: e => setChangeStateOpen(true),
        //     disableCondition: selectedTickets.length === 0
        // }
    ]

    return <IonPage id="Tickets">
        <Header title="Müraciətlər" right_buttons={[{
            label: 'Müracəiət artır',
            // isDisabled: selectedTickets.length > 0,
            onClick: () => setTicketOpen(true)
        }]} />
        <IonContent className="ion-padding">
            <DataTable
                columns={columns}
                entity={'ticket'}
                onSelectedItemsChanged={onSelectedItemsChanged}
                getDataOnSelect
                buttons={tableButtons}
                isRefetching={isRefetching}
                setRefetching={setRefetching}
            />
        </IonContent>

        <TicketModal
            onDidDismiss={() => setRefetching(true)}
            isOpen={isTicketOpen}
            setOpen={setTicketOpen}
            selectedTicket={ticketDetail}
            setSelectedTicket={setTicketDetail}
        />


        <ChangeState
            isOpen={isChangeStateOpen}
            setOpen={setChangeStateOpen}
            entity={'ticket'}
            onSuccess={onStateSuccess}
            states={ticketStates}
            entityIds={selectedTickets}
        />



    </IonPage>
}